.Mortgage-details-container {
  align-items: flex-start;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  justify-content: flex-start;
  margin: 20px;
  margin-bottom: 40px;
  width: calc(100% - 40px);
}
