.Sidebar-container {
  align-items: center;
  background-color: white;
  border: 0;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 14px 15px 14px 15px;
  width: 160px;
}

.Sidebar-icon {
  height: 15px;
  margin: 12px;
  margin-top: 10px;
  width: 15px;
}

.Sidebar-text {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 11px;
  text-align: start;
  padding-left: 8px;
}
