* {
  font-family: 'Montserrat' !important;
}

.Chart-table {
  align-items: flex-start;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  justify-content: flex-start;
  margin: 10px 8px 8px 8px;
}

.Chart-header {
  align-items: center;
  background-color: white;
  border-radius: 20px 20px 0 0;
  display: flex;
  padding-left: 20px;
  justify-content: space-between;
  width: 100%;
}

.Chart-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 350;
  padding: 0px 5px 20px 20px;
  z-index: 100;
}

.Legend-container {
  display: flex;
  flex-shrink: 1;
  margin: 10px;
  flex-direction: row;
  margin-right: 20px;
  padding-top: 6px;
}

.Legend-container-color {
  max-width: 8px;
  max-height: 8px;
  flex: 1;
  margin-top: 3.5px;
  margin-left: 8px;
  min-width: 8px;
}

.Legend-container-row {
  flex-direction: row;
  display: flex;
  flex-shrink: 1;
  margin-bottom: 4px;
  min-width: 40px;
}

.Legend-container-text {
  font-size: 10px;
  flex: 1;
  padding: 0;
  margin: 0;
  margin-left: 4px;
  font-weight: 600;
  font-family: 'Montserrat';
}
