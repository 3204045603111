:root {
  --sidebar-width: 190px;
  --header-height: 50px;
  --footer-height: 25px;
}

a, u {
  text-decoration: none !important;
}

h1, h2, h3, h4 {
  color: #212121 !important;
  line-height: normal !important;
}

.App {
  align-items: flex-start;
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  max-width: 100%;
}

.Container {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  max-width: 100%;
  margin-left: var(--sidebar-width);
}

.Content {
  align-items: flex-start;
  background-color: #f2f4f5;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: flex-start;
  margin-top: var(--header-height);
  overflow-x: clip;
  width: 100%;
}

.Sidebar {
  width: var(--sidebar-width);
  background-color: white;
  height: 100%;
}

.Chart-container {
  background: #353535;
  padding: 8px;
  border-radius: 8px;
}

.Column {
  direction: flex;
  flex: 1;
  width: 50%;
}

.Compact-table-container {
  height: 500px;
}

.Header-container {
  position: fixed;
  background-color: #fffffff1;
  width: calc(100% - var(--sidebar-width));
  z-index: 100;
}

.Header-text {
  font-size: 15px;
  font-family: 'Montserrat';
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 10px;
}

.Input-table {
  align-items: flex-start;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  justify-content: flex-start;
  margin: 20px;
  margin-bottom: 40px;
}

.Input-header {
  align-items: center;
  background-color: white;
  border-radius: 20px 20px 0 0;
  display: flex;
  padding-left: 20px;
  justify-content: flex-start;
  width: 100%;
}

.Input-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 20px 20px 20px;
}

.Logo {
  display: flex;
  flex-shrink: 1;
  font-family: "Montserrat";
  border-bottom-width: 0.25px;
  border-bottom-style: solid;
  border-bottom-color: #ebebeb;
  margin-top: 3px;
  padding-bottom: 4px;
  margin-left: 12px;
  margin-right: 12px;
}

.Logo-text {
  color: #212121;
  font-weight: 800;
  font-family: "Montserrat";
  font-size: 20px;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.Logo-mobile {
  display: flex;
  flex-shrink: 1;
  font-family: "Montserrat";
  visibility: hidden;
  display: none;
  margin-bottom: 2px;
}

.Logo-mobile-text {
  color: #212121;
  font-weight: 800;
  font-family: "Montserrat";
  font-size: 18px;
  padding: 0;
  margin: 0;
  margin-top: 6px;
}

.Mobile-banner {
  position: fixed;
  bottom: 0;
  z-index: 100;
  visibility: hidden;
  display: none;
}

.Submit-button {
  margin-top: 20px;
}

.Table-cell-text {
  align-items: center;
  align-self: stretch;
  background-color: inherit;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-align: center;
}

*[data-tooltip] {
  position: relative;
}

*[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  background-color: #f8fafb;
  color: #212121;
  border-radius: 6px;
  padding: 5px 8px;
  z-index: 1;
  font-size: 14px;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s;
  white-space: nowrap;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
}

/* Hide the sidebar for mobile. */
@media screen and (max-width: 450px) {
  .Container {
    margin-left: 0px;
  }

  .Content {
    border-top-left-radius: 0px;
  }
  .Header-container {
    width: 100%;
    padding-left: 2px;
    padding-right: 7px;
  }
  .Logo-mobile {
    visibility: visible;
    display: flex;
  }
  .Mobile-banner {
    visibility: visible;
    display: flex;
  }
  .Sidebar {
    display: none;
  }
}

.rs-message-container {
  padding: 10px 30px 10px 16px !important;
}