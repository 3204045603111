.Output-table {
    align-items: flex-start;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    justify-content: flex-start;
    margin: 10px;
    margin-bottom: 30px;
    max-width: 100%;
}

.Output-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 20px 20px 20px;
    max-width: 100%;
}

.Table-container {
    display: block;
    padding: 20px;
    padding-top: 5px;
    padding-right: 0px;
    max-width: 100%;
    overflow-x: scroll;
    width: 100%;
}
