.rs-modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 100px;
}

.rs-modal-sm,
.rs-modal-xs {
    max-width: calc(100% - 40px);
}

::selection {
    color: #fff;
    background: #1675e0;
}

ul {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
}

button {
    outline: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: 4px;
    border: 2px solid transparent;
    transition: background 0.1s linear, border-color 0.1s linear, color 0.1s linear;
}

.view-modal {
    top: 50%;
    color: #7d2ae8;
    font-size: 18px;
    padding: 10px 25px;
    background: #fff;
    transform: translate(-50%, -50%);
}

.popup :is(header, .icons, .field) {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.popup header {
    padding-bottom: 15px;
    border-bottom: 1px solid #ebedf9;
}

header span {
    font-size: 21px;
    font-weight: 600;
}

header .close,
.icons a {
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}

header .close {
    color: #878787;
    font-size: 17px;
    background: #f2f3fb;
    height: 33px;
    width: 33px;
    cursor: pointer;
}

header .close:hover {
    background: #ebedf9;
}

.popup .content {
    margin: 20px 0;
}

.content p {
    font-size: 16px;
}

.content .icons a {
    height: 50px;
    width: 50px;
    font-size: 20px;
    text-decoration: none;
    border: 1px solid transparent;
}

.icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
    transition: transform 0.3s ease-in-out;
    margin: 15px 0 20px 0;
}

.content .field {
    margin: 12px 0 -5px 0;
    height: 45px;
    border-radius: 4px;
    padding: 0 5px;
    border: 1px solid #e1e1e1;
}

.field.active {
    border-color: #1675e0;
}

.field i {
    width: 50px;
    font-size: 18px;
    text-align: center;
}

.field.active i {
    color: #7d2ae8;
}

.field input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 15px;
}

.field button {
    color: #fff;
    padding: 5px 18px;
    background: #1675e0;
}

.field button:hover {
    background: #1675e0;
}

/* Mobile formatting. */
@media screen and (max-width: 450px) {
    ul {
        padding-inline-start: 10px;
        padding-inline-end: 10px;
    }
    ul button {
        margin: 5px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .icons {
        justify-content: space-evenly !important;
    }
}