.Footer {
  position: relative;
  bottom: 0;
  width: calc(100% - 190px);
  height: 25px;
}

.Footer-text {
  color: black;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 10px;
  padding: 0;
  margin: 6px 0px 0px 4px;
  text-align: center;
}

.Footer-text-container {
  position: absolute;
  width: 100%;
  height: 25px;
  margin-right: 190px;
}

.Header {
  display: flex;
  height: 50px;
  width: 100%;
  padding: 5px;
  padding-right: 0px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Header-share-button {
  align-items: flex-start;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-shrink: 1;
  justify-content: flex-start;
  height: 28px;
  margin-right: 10px;
}

.Header-image {
  flex: 1;
  height: 35px;
  width: 35px;
  margin: 0;
  padding: 0;
  margin-top: 3px;
  margin-right: 10px;
}

.Header-green-dot {
  position: absolute;
  right: 15px;
  top: 32px;
  background-color: #04cc00;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.Header-avatar-button {
  align-items: flex-start;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-shrink: 1;
  justify-content: flex-start;
}

.Header-title-text {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 15px;
  text-align: start;
  text-overflow: ellipsis;
  padding: 0;
  margin: 0;
  margin-top: 2px;
  margin-bottom: 2px;
  white-space: nowrap;
}

.Header-description-text {
  font-size: 10px;
  text-align: start;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 11px;
  text-align: start;
  padding: 0;
  margin: 0;
}

.Mortage-analysis {
  max-width: calc(100vw - 190px);
  width: calc(100vw - 190px);
  padding: 10px;
}

.Input-container {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.Input-details {
  align-items: flex-start;
  background-color: white;
  border-radius: 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: black;
  display: flex;
  justify-content: flex-start;
  margin: 10px 8px 5px 8px;
  text-decoration: none;
  min-width: 275px;
  width: calc(50% - 16px);
  flex-grow: 1;
  overflow: hidden;
}

.Input-text-container {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  width: 100%;
}

.Split-chart {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .Split-chart {
    width: 100%;
  }
}

.Line-chart-container {
  background-color: #f2f4f5;
  margin-top: 0px;
  margin-bottom: 20px;
}

.Split-chart-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10;
  margin-bottom: 0px;
}

.rs-picker-toggle-value {
  color: #575757 !important;
}

/* Mobile formatting. */
@media screen and (max-width: 450px) {
  .Header {
    padding-left: 12px;
  }
  .Header-avatar-button {
    display: none;
    visibility: hidden;
    width: 0;
  }
  .Header-title-text {
    display: none;
    visibility: hidden;
  }
  .Header-description-text {
    display: none;
    visibility: hidden;
  }
  .Sidebar {
    display: none;
    visibility: hidden;
    width: 0;
  }
  .Mortage-analysis {
    max-width: 100%;
    width: 100%;
  }
  .Footer {
    width: 100%;
  }
  .Footer-text-container {
    margin-right: 0px;
  }
  .Mortage-analysis {
    margin: 0;
  }
}